<template>
  <div>
    <a href="http://www.s-system.co.jp/gaina/index.html" target="_blank" id="gaina" class="products container neumorphism">
      <div id="gaina-logo" class="img"></div>
      <div class="products-message">
        <h1 class="message-title">
          <span>ガイナ</span>
          <span>システム</span>
          <span>シリーズ</span>
        </h1>
        <p class="message-sentence">
          <span class="f">導入事例の多い業種を選定し、</span>
          <br />
          <span>その集大成した基本的な</span>
          <span>業務オリジナルシステムを</span>
          <span>イージーオーダーパッケージの</span>
          <span>雛型(ひながた)として活用すれば、</span>
          <span>大幅な低価格化が可能。</span>
          <br />
          <span>カスタマイズ費用を低価格に抑え、</span>
          <span>計算方法を明確にし</span>
          <span>ユーザー様と価値観を</span>
          <span>共有する数式を提示。</span>
          <br />
          <span>エリアを中心とした</span>
          <span>従来のマーケティングを廃し、</span>
          <br />
          <span>マーケティングエリアの拡大による</span>
          <span>御利用ユーザーの増加により、</span>
          <span>採算性を確保する。</span>
        </p>
      </div>
    </a>
    <a href="http://www.s-system.co.jp/MATS_HP/index.html" target="_blank" id="toprunner" class="products container neumorphism">
      <div id="toprunner-logo" class="img"></div>
      <div class="products-message">
        <h1 class="message-title">
          <span>トップランナー</span>
          <span>システム</span>
          <span>シリーズ</span>
        </h1>
        <p class="message-sentence">
          <span class="f">医師会・歯科医師会事務局の</span>
          <span>業務を効率化するために</span>
          <span>開発されたソフトです。</span>
          <span>会員の管理から</span>
          <span>会費等の請求・支払まで</span>
          <span>トータル管理できます。</span>
          <span>導入実績10団体以上。</span>
          <span>このソフトを加工して</span>
          <span>他団体への導入も可能です。</span>
        </p>
      </div>
    </a>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'  // ①
 
export default {
    name:'foot',
  components: {
    Footer
  }
}
</script>

<style lang="scss" scoped>
.container {
  justify-self: center;
  background-color: transparent;
  width: 1200px;
  margin: 2.5rem;
  padding: 2.5rem;
  text-decoration: none;
  color: inherit;
}
.products {
  .products-message {
    .message-title {
      span {
        display: inline-block;
        text-align: center;
      }
      font-size: 3rem;
      text-align: center;
    }
    .message-sentence {
      font-size: 2.25rem;
      font-weight: 700;
      br + span {
        margin-top: 1rem;
      }
      span {
        display: inline-block;
        text-align: center;
      }
      text-align: center;
    }
  }
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;
  align-items: center;
  font-family: var(--title-font);
}
#gaina {
  #gaina-logo {
    width: 300px;
    height: 300px;
    justify-self: center;
    background-image: url("/assets/img/products/gaina.png");
  }
}
#toprunner {
  #toprunner-logo {
    width: 300px;
    height: 300px;
    justify-self: center;
    background-image: url("/assets/img/products/toprunner.png");
  }
}
@media (max-width: 1359px) {
  .container {
    width: calc(960px - 10rem);
  }
}
@media (max-width: 959px) {
  .container {
    width: calc(100% - 10rem);
    border-radius: 0rem;
  }
}
@media (max-width: 750px) {
  .container {
    margin: 2rem;
    padding: 2rem;
    width: calc(100% - 8rem);
  }
  .products {
    .products-message {
      .message-sentence {
        font-size: 1.85rem;
        font-weight: 700;
      }
    }
  }
}
@media (max-width: 519px) {
  .container {
    margin: 1rem;
    padding: 1rem;
    width: calc(100% - 4rem);
  }
  .products {
    .products-message {
      .message-sentence {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
  }
  #gaina {
    #gaina-logo {
      width: 250px;
      height: 250px;
    }
  }
  #toprunner {
    #toprunner-logo {
      width: 250px;
      height: 250px;
    }
  }
}
</style>

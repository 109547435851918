<template>
  <div>
    <div id="landing">
      <img
        class="light"
        src="/assets/img/home/n1_1.png"
        width="100%"
        alt=""
      />
      <img class="dark" src="/assets/img/home/n9.png" width="100%" alt="" />
    </div>
    <div id="home">
      <router-link to="/About" class="container neumorphism pressed">
        <img class="post-img" src="/assets/img/home/n5.png" alt="" />
        <div class="post-card">
          <h1 class="card-title">会社情報</h1>
          <p class="card-sentence">
            <span>私たちはソフトウェア開発会社です。</span>
            <span>40年間続けることができたことは</span>
            <span>お付き合いしてくれた皆様のおかげです。</span>
          </p>
        </div>
      </router-link>
      <router-link to="/Products" class="container neumorphism pressed">
        <img class="post-img" src="/assets/img/home/n4.png" alt="" />
        <div class="post-card">
          <h1 class="card-title">商品情報</h1>
          <p class="card-sentence">
            <span> SSPが自社開発をしている</span>
            <span> パッケージソフトウェアシリーズ</span>
          </p>
        </div>
      </router-link>
      <router-link to="/Initiatives" class="container neumorphism pressed">
        <img class="post-img" src="/assets/img/home/n1.png" alt="" />
        <div class="post-card">
          <h1 class="card-title">SSPの取り組み</h1>
          <p class="card-sentence">
            <span>プライバシーマークの認定取得、</span>
            <span>情報処理安全確保支援士の確保、</span>
            <span>SDGsへの取り組みを行っています。</span>
          </p>
        </div>
      </router-link>
      <router-link to="/Recruitment" class="container neumorphism pressed">
        <img class="post-img" src="/assets/img/home/n6.png" alt="" />
        <div class="post-card">
          <h1 class="card-title">採用情報</h1>
          <p class="card-sentence">
            <span>SSPはITに興味がある</span>
            <span>あなたを求めています。</span>
            <span>どんな会社?</span>
            <span>どんな仕事?</span>
            <span>ズバリお答えします。</span>
          </p>
        </div>
      </router-link>
      <router-link to="/News" class="container neumorphism pressed">
        <img class="post-img" src="/assets/img/home/n2.png" alt="" />
        <div class="post-card">
          <h1 class="card-title">ニュース</h1>
          <p class="card-sentence">
            <span>セミナー、イベント</span>
            <span>新商品、導入実績など</span>
            <span>SSPの活動をお知らせします。</span>
          </p>
        </div>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue"; // ①

export default {
  name: "foot",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
#landing {
  .dark {
    display: none;
  }
  @media (prefers-color-scheme: dark) {
    .light {
      display: none;
    }
    .dark {
      display: block;
    }
  }
}
#home {
  .container {
    .post-img {
      position: relative;
      width: 100%;
    }
    .post-card {
      .card-sentence {
        span {
          display: inline-block;
        }
      }
      text-align: center;
      font-family: var(--title-font);
      font-size: 1.6rem;
      margin: 1rem;
    }
    display: grid;
    margin: 5px;
    margin: 2.5rem 0px;
    text-decoration: none;
    color: inherit;
    background-color: transparent;
  }
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 500px));
  justify-content: center;
  gap: 50px;
}

@media (min-width: 1359px) {
  #home {
    padding: 5rem 0px;
  }
}
@media (min-width: 959px) {
  #home {
    padding: 3.5rem;
  }
}
@media (min-width: 750px) {
  #home {
    padding: 2.5rem;
  }
}
@media (min-width: 519px) {
  #home {
    padding: 1.5rem;
  }
}
</style>

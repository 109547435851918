<template>
	<div>
		<div class="container greeding">
			<div class="greeding-message">
				<h1 class="message-title">はじめに</h1>
				<p class="message-sentence">
					<span>
						株式会社SSPは、個人情報保護に関するコンプライアンス・プログラムの要求項目（JISQ
						15001）を満たす企業として
						財団法人日本情報処理開発協会（JIPDEC）より「プライバシーマーク」付与の認定を受けています。（2008年4月30日から）
					</span>
					<br />
					<br />
					<span>
						当社では「個人情報保護方針」の策定を中心に、管理責任者の配置、管理マニュアルの作成を行い、お客様のプライバシー保護に関する管理徹底を遂行しています。
					</span>
				</p>
			</div>
		</div>
		<div class="container greeding">
			<div class="greeding-message">
				<h1 class="message-title">個人情報保護方針</h1>
				<p class="message-sentence">
					<span>
						株式会社SSP（以下、当社と総称します）は業務上、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言し、個人情報保護に勤めます。
					</span>
					<br />
					<br />
					<span>
						a)
						当社の取り扱う個人情報はシステム開発業務において当社の事業遂行上並びに従業員の雇用に関する人事管理において必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取り扱いを禁止する措置を講じます
						。
					</span>
					<br />
					<br />
					<span>
						b)
						当社は個人情報保護法をはじめとする個人情報に関連する法規制、国が定める指針及びその他の規範について遵守致します。
					</span>
					<br />
					<br />
					<span>
						c)
						当社の管理下にある個人情報の漏洩、滅失、き損などのリスクに関しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し、個人情報セキュリティを継続的に向上させ、事故が発生した際には速やかに是正措置を講じます。
					</span>
					<br />
					<br />
					<span>
						d)
						当社は個人情報の取扱いに関する苦情及び相談に関しては、迅速かつ適切に対応いたします。
					</span>
					<br />
					<br />
					<span>
						e)
						個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。
					</span>
					<br />
					<span class="vert">
						<span>【お問い合わせ窓口】</span>
						<span>
							個人情報保護方針に関するお問い合わせにつきましては、下記窓口で受け付けております。
						</span>
						<br />
						<span>〒791-8013 愛媛県松山市山越６丁目17番11号</span>
						<span>株式会社SSP 個人情報保護管理者</span>
						<span>TEL:089-924-7080（対応可能時間 9:00~17:00）</span>
						<span>
							※土・日・祝祭日、年末年始、ゴールデンウィーク、夏季休暇期間は翌営業日以降の対応とさせていただきます。また、在宅勤務制度により営業日であっても担当が不在の場合、翌営業日以降の対応とさせていただきます。
						</span>
					</span>
				</p>
				<p class="message-sentence footer">
					<span><span>制定日</span> <span>平成18年9月1日</span></span>
					<br />
					<span><span>改訂日</span> <span>令和3年4月28日</span></span>
					<br />
					<span>株式会社SSP</span>
					<br />
					<span><span>代表取締役</span> <span><span>岩本</span> <span>佳</span></span></span>
				</p>
			</div>
		</div>
		<div class="container greeding">
			<div class="greeding-message">
				<h1 class="message-title">
					<span>個人情報の取り扱いについて</span>
				</h1>
				<p class="message-sentence">
					<span
						>個人情報の保護に関する法律に基づき、以下の項目について公表します。</span
					>
					<br />
					<span class="vert">
						<span>【公表事項】</span>
						<span>1.事業者の名称</span>
						<span>2.個人情報の管理者又は職名、所属及び連絡先</span>
						<span>3.個人情報の利用目的の公表に関する事項</span>
						<span>4.個人情報の第三者提供に関する事項</span>
						<span>5.個人情報の取扱いの委託</span>
						<span>6.「開示等の求め」に応じる手続に関する事項</span>
						<span>7.個人情報を提供されることの任意性について</span>
						<span
							>8.ご本人が容易に認識できない方法により取得する場合について</span
						>
						<span>9.認定個人情報保護団体について</span>
						<span>10.「苦情」の受付窓口に関する事項</span>
					</span>
					<br />
					<span class="vert">
						<span>1. 事業者の名称</span>
						<span>株式会社SSP</span>
					</span>
					<br />
					<span class="vert">
						<span>2. 個人情報の管理者又は職名、所属及び連絡先</span>
						<span>代表取締役 岩本佳</span>
						<span>連絡先：089-924-7080</span>
					</span>
					<br />
					<span class="vert">
						<span>3. 個人情報の利用目的の公表に関する事項</span>
						<span>
							1）直接個人情報を取得する場合及び間接的に個人情報を取得する場合の利用目的並びに当社が保有する個人情報の利用目的は次のとおりです。
						</span>
						<span>① 商品の販売に関するお問合せの対応</span>
						<span
							>②
							各種サービスのお申し込みの確認と各種サービスの提供の対応</span
						>
						<span
							>③
							資料請求に関するお問合せへの対応（カタログ送付）</span
						>
						<span>④ セミナー・展示会等へのご案</span>
						<span>⑤ 製品に関するお問合せへの対応</span>
						<span
							>⑥
							システム開発に伴うデータ入力及び移行作業の履行</span
						>
						<span>⑦ システムサポートでリモート保守による対応</span>
						<span>⑧ 個人情報に関するお問合せへの対応</span>
						<span>⑨ お客様との業務上のご連絡</span>
						<span>⑩ 外来者の入館管理</span>
						<span>⑪ 会社施設内への入退館管理</span>
						<span>⑫ 採用応募者の選考に関わる連絡、事務処理</span>
						<span>⑬ 当社役員及び従業員の人事管理</span>
						<span
							>⑭
							セキュリティ管理（防犯、防災、労働安全衛生を含む）</span
						>
						<span
							>⑮ その他、ご本人に事前に同意をいただいた目的</span
						>
						<span
							>なお、お客様から直接書面にて記載された個人情報をお預かりする場合は、その都度利用目的を明示させていただきます。ただし、次の場合を除きます。</span
						>
						<br />
						<span>
							(1)利用目的を本人に通知し、又は公表することにより本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
						</span>
						<br />
						<span>
							(2)利用目的を本人に通知し、又は公表することにより当社の権利又は正当な利益を害するおそれがある場合
						</span>
						<br />
						<span>
							(3)国の機関又は地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的を本人に通知し、又は公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき
						</span>
						<br />
						<span>
							(4)取得の状況からみて利用目的が明らかであると認められる場合
						</span>
					</span>
					<br />
					<span class="vert">
						<span>
							4.個人情報の第三者提供に関する事項当社は、お客様から取得した個人情報を予めお客様の同意を得ることなく第三者に提供することはありません。ただし、以下のいずれかに該当する場合は、ご本人の同意を得ずに対処する場合があります。
						</span>
						<br />
						<span>
							(1)法令により開示を求められた場合
						</span>
						<br />
						<span>
							(2)本人または公衆の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。
						</span>
						<br />
						<span>
							(3)公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。
						</span>
						<br />
						<span>
							(4)国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
						</span>
					</span>
					<br />
					<span class="vert">
						<span>
							5.個人情報の取扱いの委託個人情報を委託する場合は、委託先企業と委託業務契約等の契約を行っております。
						</span>
					</span>
					<br />
					<span class="vert">
						<span>
							6.「開示等の求め」に応じる手続に関する事項
						</span>
						<span>
							当社は、保有する個人情報（1.1記載のもの）の本人又はその代理人（法定代理人／委任に基づく代理人）からの「利用目的の通知、個人情報の開示、訂正、利用停止・消去、第三者提供停止の求め又は第三者提供記録（開示のみ）」（「開示等の求め」といいます）について、以下の手続等に基づき対応させていただきます。
						</span>
						<br />
						<span>(1)「開示等の求め」に応じる申請方法</span>
						<span
							>「開示等の求め」に当っては、以下の提出が必要となります。</span
						>
						<span>・(3)項に定める「個人情報開示等依頼書」</span>
						<span
							>・(3)項に定める「本人、代理人確認のための書類」</span
						>
						<span
							>電話、電子メール、来社等本項の方法によらない申請については、応じかねますので、ご理解願います。</span
						>
						<span
							>なお、「開示等の求め」に際しご提出（郵送）いただいた書面については、返却いたしかねますので、予めご了承願います。</span
						>
						<span
							>※「開示等の求め」には、”利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止”があります。</span
						>
						<br />
						<span>(2)「開示等の求め」の申請先</span>
						<span>開示等の求めに応じる手続き</span>
						<span>〒791-8013 愛媛県松山市山越６丁目17番11号</span>
						<span>株式会社SSP 代表取締役 岩本佳</span>
						<span>TEL:089-924-7080（対応可能時間 9:00~17:00）</span>
						<br />
						<span>(3)「開示等の求め」の際に提出いただく書面</span>
						<br />
						<span>a.本人による申請の場合</span>
						<span>
							①個人情報開示等依頼書
							<a href="/assets/pdf/IRAISYO.pdf" target="_blank"
								>個人情報開示等依頼書（ダウンロード）</a
							>
						</span>
						<span>②ご本人の本人確認書類</span>
						<span
							>ご本人確認のため、以下の1)～4)の有効期限内のもの、又は発行より３ヶ月以内のもので、氏名、住所、生年月日が確認できるものを1通ご用意願います。また、運転免許証・パスポートの写しを添付する場合は本籍情報を隠してご送付願います。</span
						>
						<span>1) 運転免許証の写し</span>
						<span>2) 学生証の写し</span>
						<span>3) パスポートの写し</span>
						<span>4) 健康保険証の写し</span>
						<br />
						<span>ｂ.代理人による申請の場合</span>
						<span
							>開示等の求めを未成年者又は成年被後見人の法定代理人、若しくは本人が委任した代理人が行う場合には、下記の書類をご提出願います。</span
						>
						
						<span>①開示等を求めている依頼者本人が確認できる書類</span>
						<span>
							②個人情報開示等依頼書
							<a href="/assets/pdf/IRAISYO.pdf" target="_blank"
								>個人情報開示等依頼書（ダウンロード）</a
							>
						</span>
						<span>③代理人の資格証明書類</span>
						<span>④代理人本人が確認できる書類</span>
						<span
							>委任代理人の場合は委任状、未成年後見人や成年後見人等の法定代理人の場合は資格を示す書類（戸籍謄本、後見登記事項証明書等）をご用意願います。</span
						>
						<span
							>※書類に本籍地等の要配慮個人情報が記載されている場合、塗りつぶすなど隠すようにしてご送付願います。</span
						>
					</span>
					<br />
					<span class="vert">
						<span>7.開示等の求めに関する事務手数料の費用に関して</span>
						<span>
							当社は、本人もしくは代理人より求められた個人情報の開示等による事務手数料は徴収致しません。
						</span>
					</span>
					<br />
					<span class="vert">
						<span>8.個人情報を提供されることの任意性について</span>
						<span>
							お客様が当社に個人情報を提供されるかどうかは、お客様の任意によるものです。
							ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。
						</span>
					</span>
					<br />
					<span class="vert">
						<span>
							9.
							ご本人が容易に認識できない方法により取得する場合について
							クッキー(Cookies)は、お客様が当社のサイトに再度訪問された際、より便利に当サイトを閲覧していただくためのものであり、お客様のプライバシーを侵害するものではなく、お客様のコンピューターへ悪影響を及ぼすことはありません。
						</span>
						<span>
							また当社のサイトでは個人情報を入力していただく部分にはすべてSSLのデータ暗号化システムを利用しております。ただし、インターネット通信の性格上、セキュリティを安全に保証するものではありません。あらかじめご了承ください。
						</span>
					</span>
					<br />
					<span class="vert">
						<span> 10.認定個人情報保護団体について</span>
						<span>
							一般財団法人日本情報経済社会推進協会
							認定個人情報保護団体事務局</span
						>
						<span>
							〒106-0032 東京都港区六本木1-9-9
							六本木ファーストビル内</span
						>
						<span> TEL：03-5860-7565、0120-700-779</span>
						<span>
							当社との個人情報に関する苦情について、当事者間で解決できなかった場合は、上記の認定個人情報保護団体に、その解決について助力を求めるために申出をすることができます。</span
						>
					</span>
					<br />
					<span class="vert">
						<span> 11.「苦情」の受付窓口に関する事項</span>
						<span>
							個人情報の取り扱いに関するお問い合せは、苦情及びご相談は、次の窓口までご連絡ください。
						</span>
					</span>
					<br />
					<span class="vert">
						<span>
							【個人情報取り扱い窓口】
						</span>
						<span> 〒791-8013 愛媛県松山市山越6丁目17番11号</span>
						<span> 株式会社SSP 苦情相談窓口係</span>
						<span>
							TEL：089-924-7080（対応可能時間9:00～17:00）</span
						>
					</span>
					<br />
					<span class="vert">
						<span> 12.安全管理のための措置</span>
						<span>
							当社は、保有する個人データの適正な取扱いを確保するため「個人情報保護方針」に従い、以下の安全管理を実施しています。
						</span>
						<span>
							(1)組織的安全管理措置
						</span>
						<span>
							個人情報保護、情報セキュリティに関して日常的及び緊急時に適切な対応を速やかに行えるよう体制を構築しています。
						</span>
						<span>
							(2)人的安全管理措置
						</span>
						<span>
							入社時及び退社時には秘密保持に関する誓約書を締結し、定期的に個人情報の保護教育を実施しています。
						</span>
						<span>
							(3)物理的安全管理措置
						</span>
						<span>
							入退室の記録を採取及びチェックしています。また、権限を有しない者による、個人データ等、重要な情報へのアクセス防止措置を行っています。
						</span>
						<span>
							(4)技術的安全管理措置
						</span>
						<span>
							サーバー、パソコンへの利用者を限定かつパスワード認証による管理を実施しています。併せてアクセスログの管理を実施しています。
						</span>
						<span>
							(5)外的環境の把握に関すること
						</span>
						<span>
							外国において個人情報を取扱う場合は、当該国の法制度等を把握し、適切な措置を講じる。なお、現在のところ、当社は外国においての取扱いはありません。
						</span>
					</span>
					<br />
				</p>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'; // ①

export default {
	name: 'foot',
	components: {
		Footer,
	},
};
</script>

<style lang="scss" scoped>
@import url('https://use.typekit.net/bux4heh.css');
.container {
	justify-self: center;
	background-color: transparent;
	width: 1200px;
	margin: 2.5rem;
	padding: 2.5rem;
}
span {
	display: inline-block;
}
.greeding {
	.greeding-message {
		.message-title {
			font-size: 3rem;
		}
		.message-sentence {
			font-size: 1.6rem;
			font-weight: 700;
			br + span {
				// margin-top: 2.5rem;
			}
			span {
				display: inline-block;
				text-align: left;
				&.vert {
					display: flex;
					flex-direction: column;
				}
			}
		}
		.footer {
			text-align: right;
			line-height: 1.6rem;
		}
	}
	display: grid;
	grid-auto-flow: column;
	gap: 1.5rem;
	align-items: center;
	font-family: var(--title-font);
}

@media (max-width: 1359px) {
	.container {
		width: calc(960px - 10rem);
	}
}
@media (max-width: 959px) {
	.container {
		width: calc(100% - 10rem);
		border-radius: 0rem;
	}
	.greeding {
		grid-auto-flow: row;
		text-align: left;
	}
}
@media (max-width: 750px) {
	.container {
		margin: 2rem;
		padding: 2rem;
		width: calc(100% - 8rem);
	}
}
@media (max-width: 519px) {
	.container {
		margin: 1rem;
		padding: 1rem;
		width: calc(100% - 4rem);
	}
}
</style>

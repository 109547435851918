<template>
	<div>
		<div id="employer" class="recruitment greeding container neumorphism">
			<div class="greeding-message">
				<h1 class="message-title">採用責任者</h1>
				<p class="message-sentence">
					<span class="f">私たちの仕事は企業の</span>
					<span>「ヒト・モノ・カネ・情報」を</span>
					<span>データ管理するための</span>
					<span>システムを作り、</span>
					<span>運用をサポートすることです。</span>
					<br />
					<span class="f">システムはお客様が会社を</span>
					<span>経営していくための</span>
					<span>道具であり</span>
					<span>頭脳でなければなりません。</span>
					<br />
					<span class="f">企業に寄り添い、</span>
					<span>悩みを聞き出し、</span>
					<span>不満を解消してあげる。</span>
					<span>それが私たちの役割です。</span>
					<br />
					<span class="f">お客様の作業時間を</span>
					<span>『1時間を1分に』することを</span>
					<span>目標にSSPは日々活動しています。</span>
					<br />
					<span class="f">システム開発はヒトがいなければ</span>
					<span>作ることはできません。</span>
					<br />
					<span class="f">１つのプロジェクトでは</span>
					<span>数人のメンバーと協力して</span>
					<span>開発しており、個々の強みを生かして</span>
					<span>開発しています。</span>
					<br />
					<span class="f">あなたのアイデアも私たちの会社では</span>
					<span>大きなチカラとなります。</span>
					<br />
					<span class="f">私たちと一緒にシステムを利用する</span>
					<span>すべてのお客様を幸せにしませんか？</span>
				</p>
			</div>
			<center class="greeding-people">
				<img
					class="people-img"
					src="/assets/img/recruitment/n1.png"
					alt=""
				/>
				<div class="people-description">
					<center class="pos">代表取締役社長</center>
					<center class="name">岩本 佳</center>
				</div>
			</center>
		</div>
		<div id="recruitment-data" class="recruitment container">
			<div class="recruitment-message">
				<h1 class="message-title">採用データ</h1>
			</div>
			<div class="wrapper">
				<div class="value data-img neumorphism">
					<img
						class="img-img"
						src="/assets/img/recruitment/male-female-ratio.png"
						alt=""
					/>
					<h1 class="img-title">男女比率</h1>
				</div>
				<div class="value data-img neumorphism">
					<img
						class="img-img"
						src="/assets/img/recruitment/recruitment-ratio.png"
						alt=""
					/>
					<h1 class="img-title">採用比率</h1>
				</div>
				<div class="value data-img neumorphism">
					<img
						class="img-img"
						src="/assets/img/recruitment/previous-job-ratio.png"
						alt=""
					/>
					<h1 class="img-title">前職割合</h1>
				</div>
				<div class="value data-img neumorphism">
					<img
						class="img-img"
						src="/assets/img/recruitment/years-of-service.png"
						alt=""
					/>
					<h1 class="img-title">勤続年数</h1>
				</div>
			</div>
		</div>
		<div id="recruitment-video" class="recruitment container neumorphism">
			<div class="recruitment-message">
				<h1 class="message-title">
					<span>新卒採用</span>
					<span>プロモーション</span>
					<span>ビデオ2022</span>
				</h1>
			</div>
			<div class="wrapper">
				<div class="value">
					<iframe
						class="yt-link"
						width="560"
						height="315"
						src="https://www.youtube.com/embed/L-HEAvQAHDA"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</div>
		</div>
		<div id="recruitment-jobs" class="recruitment container neumorphism">
			<div class="recruitment-message">
				<h1 class="message-title">
					<span>募集一覧</span>
				</h1>
			</div>
			<div id="jobs-content">
				<div class="jobs">
					<h1 class="jobs-title">開発部・システムエンジニア</h1>
					<ul class="jobs-value">
						<li>
							<span>雇用形態</span>
							<p>
								<span>正社員</span>
							</p>
						</li>
						<li>
							<span>応募資格</span>
							<p>
								<span>高卒以上</span>
							</p>
						</li>
						<li>
							<span>勤務先</span>
							<p>
								<span>SSP本社</span>
							</p>
						</li>
						<li>
							<span>勤務時間</span>
							<p>
								<span>9 a.m. - 5:30 p.m.</span>
							</p>
						</li>
						<li>
							<span>通勤方法</span>
							<p>
								<span>自由</span>
								<span>(自家用車は別途相談)</span>
							</p>
						</li>
						<li>
							<span>休日</span>
							<p>
								<span>土日祝 | </span>
								<span>年末年始 | </span>
								<span>GW | </span>
								<span>お盆</span>
							</p>
						</li>
						<li>
							<span>福利厚生</span>
							<p>
								<span>労災保険 | </span>
								<span>退職金共済 | </span>
								<span>ウォータサーバー設置</span>
							</p>
						</li>
						<li>
							<span>各種手当</span>
							<p>
								<span>住宅手当 | </span>
								<span>家族手当 | </span>
								<span>資格手当 | </span>
								<span>役職手当 | </span>
								<span>職能手当 | </span>
								<span>精勤手当 | </span>
								<span>交通費</span>
							</p>
						</li>
						<li>
							<span>求める人物</span>
							<p>
								<span>元気で明るくお客様の為に頑張れる人</span>
								<br />
								<span>弱音を吐かずに前向きに仕事に</span>
								<span>打ち込める人</span>
								<br />
								<span>コミュニケーション能力が高い人</span>
							</p>
						</li>
						<li class="link">
							<span>エントリー</span>
							<a
								href="https://job.mynavi.jp/24/pc/search/corp208391/outline.html"
								target="_blank"
							>
								<img
									src="https://job.mynavi.jp/conts/kigyo/2024/logo/banner_entry_160_45.gif"
									alt="マイナビ2024"
									border="0"
								/>
							</a>
						</li>
					</ul>
				</div>
				<div class="jobs">
					<h1 class="jobs-title">営業部・提案型営業</h1>
					<ul class="jobs-value">
						<li>
							<span>雇用形態</span>
							<p>
								<span>正社員</span>
							</p>
						</li>
						<li>
							<span>応募資格</span>
							<p>
								<span>高卒以上</span>
							</p>
						</li>
						<li>
							<span>勤務先</span>
							<p>
								<span>SSP本社</span>
							</p>
						</li>
						<li>
							<span>勤務時間</span>
							<p>
								<span>9 a.m. - 5:30 p.m.</span>
							</p>
						</li>
						<li>
							<span>通勤方法</span>
							<p>
								<span>自由</span>
								<span>(自家用車は別途相談)</span>
							</p>
						</li>
						<li>
							<span>休日</span>
							<p>
								<span>土日祝 | </span>
								<span>年末年始 | </span>
								<span>GW | </span>
								<span>お盆</span>
							</p>
						</li>
						<li>
							<span>福利厚生</span>
							<p>
								<span>労災保険 | </span>
								<span>退職金共済 | </span>
								<span>ウォータサーバー設置</span>
							</p>
						</li>
						<li>
							<span>各種手当</span>
							<p>
								<span>住宅手当 | </span>
								<span>家族手当 | </span>
								<span>資格手当 | </span>
								<span>役職手当 | </span>
								<span>職能手当 | </span>
								<span>精勤手当 | </span>
								<span>交通費</span>
							</p>
						</li>
						<li>
							<span>求める人物</span>
							<p>
								<span>元気で明るくお客様の為に</span>
								<span>頑張れる人</span>
								<br />
								<span>弱音を吐かずに前向きに仕事に</span>
								<span>打ち込める人</span>
								<br />
								<span>コミュニケーション能力が高い人</span>
							</p>
						</li>
						<li class="link">
							<span>エントリー</span>
							<a
								href="https://job.mynavi.jp/22/pc/search/corp208391/outline.html"
								target="_blank"
							>
								<img
									src="https://job.mynavi.jp/conts/kigyo/2022/logo/banner_entry_160_45.gif"
									alt="マイナビ2022"
									border="0"
								/>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<real-opinions />
		<Footer />
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import RealOpinions from '@/components/RealOpinions.vue';

export default {
	name: 'foot',
	components: {
		RealOpinions,
		Footer,
	},
};
</script>

<style lang="scss" scoped>
.container {
	justify-self: center;
	background-color: transparent;
	width: 1200px;
	margin: 2.5rem;
	padding: 2.5rem;
}
#employer {
	.greeding-people {
		.people-img {
			height: 300px;
		}
		.people-description {
			.pos {
				font-size: 1.6rem;
			}
			.name {
				font-size: 2.5rem;
			}
			font-weight: 700;
		}
	}
	.greeding-message {
		.message-title {
			&::after {
				content: '・ご挨拶';
			}
			font-size: 3rem;
		}
		.message-sentence {
			font-size: 1.6rem;
			font-weight: 700;
			br + span {
				margin-top: 2.5rem;
			}
			span {
				display: inline-block;
				text-align: center;
			}
		}
	}
	display: grid;
	grid-auto-flow: column;
	gap: 1.5rem;
	align-items: center;
	font-family: var(--title-font);
}
.recruitment {
	.recruitment-message {
		.message-title {
			span {
				display: inline-block;
				text-align: center;
			}
			font-size: 3rem;
			text-align: center;
		}
	}
	.wrapper {
		.value {
			.img-title {
				span {
					display: inline-block;
				}
				text-align: center;
			}
			position: relative;
		}
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: 2rem;
	}
	display: grid;
	grid-auto-flow: row;
	gap: 1.5rem;
	align-items: center;
	font-family: var(--title-font);
}
#recruitment-data {
	.wrapper {
		.value {
			.img-img {
				width: 250px;
			}
			display: grid;
			grid-auto-flow: row;
			justify-content: center;
		}
	}
}
#recruitment-video {
	.wrapper {
		.value {
			.yt-link {
				width: 1080px;
				height: 608px;
			}
			display: grid;
			grid-auto-flow: row;
			justify-content: center;
		}
	}
}
.jobs-value {
	li {
		display: grid;
		grid-template-columns: 100px 1fr;
		align-items: center;
	}
	span {
		display: inline-block;
		text-align: center;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 1px;
		height: 100%;
		background-color: var(--fgc-pri);
	}
	position: relative;
	margin-inline-start: 3rem;
	padding-inline-start: 5rem;
	font-size: 1.6rem;
	font-weight: 700;
}
ul {
	list-style: none;
}
@media (max-width: 1359px) {
	.container {
		width: calc(960px - 10rem);
	}
	#recruitment-video {
		.wrapper {
			.value {
				.yt-link {
					width: 800px;
					height: 450px;
				}
			}
		}
	}
}
@media (max-width: 959px) {
	.container {
		width: calc(100% - 10rem);
		border-radius: 0rem;
	}
	#employer {
		grid-auto-flow: row;
	}
	#recruitment-video {
		.wrapper {
			.value {
				.yt-link {
					width: 600px;
					height: 338px;
				}
			}
		}
	}
}
@media (max-width: 750px) {
	.container {
		margin: 2rem;
		padding: 2rem;
		width: calc(100% - 8rem);
	}
	#recruitment-video {
		.wrapper {
			.value {
				.img-img {
					width: 400px;
				}
				.yt-link {
					width: 400px;
					height: 225px;
				}
			}
		}
	}
	.jobs-value {
		&::before {
			display: none;
		}
		li {
			span {
				text-align: left;
			}
			p {
				padding-inline-start: 1.5rem;
				margin-block-start: 0.5rem;
			}
			grid-auto-flow: row;
			grid-template-rows: auto 1fr;
			grid-template-columns: unset;
			border-bottom: 1px solid var(--bgc-sec);
		}
		padding-inline-start: 0rem;
		margin-left: 1rem;
	}
}
@media (max-width: 519px) {
	.container {
		margin: 1rem;
		padding: 1rem;
		width: calc(100% - 4rem);
	}
	#employer {
		.greeding-message {
			.message-title {
				&::after {
					content: '\Aご挨拶';
				}
				white-space: pre;
			}
			text-align: center;
		}
	}
	#recruitment-video {
		.wrapper {
			.value {
				.yt-link {
					width: 300px;
					height: 168.75px;
				}
			}
		}
	}
}
</style>

import { render, staticRenderFns } from "./Initiatives.vue?vue&type=template&id=07550167&scoped=true&"
import script from "./Initiatives.vue?vue&type=script&lang=js&"
export * from "./Initiatives.vue?vue&type=script&lang=js&"
import style0 from "./Initiatives.vue?vue&type=style&index=0&id=07550167&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07550167",
  null
  
)

export default component.exports
<template>
	<div>
		<div class="container neumorphism pressed">
			<span class="msg date">2021/11/17</span>
			<div class="wrap">
				<div class="text">
					<h1 class="msg title">
						<span>2022年度新卒内定式を行いました。</span>
					</h1>
					<p class="msg sentence">
							<span>
								内定式の流れ
							</span>
							<ul class="msg list numbering">
								<li>代表取締役会長 岩本透 挨拶</li>
								<li>内定証書授与</li>
								<li>内定者代表の挨拶</li>
								<li>所属部署 部長 挨拶</li>
								<li>交流会</li>
							</ul>
							<span>
								2022卒は男性1名、女性1名の計2名の学生が内定を承諾してくださいました。
							</span>
							<span>
								今回はコミュニティセンターで会場をお借りして開催してみました。
								内定式自体がは当社初の試みということで、終始緊張感が漂っておりましたが
								交流会の「年の差クイズ」では緊張もほどけ、盛り上がっていた気がします。
							</span>
							<br />
							<span>
								内定者お二人の真剣な眼差しやフレッシュさが
								私たちにとって良い刺激になりました。
								4月に入社していただくまでの間、より一層気を引き締めて準備をしていこうと決意した、そんな有意義な内定式でした。
							</span>
						</p>
				</div>
				<div class="img">
					<img class="content" src="/assets/img/news/n1.png" alt="">
					<img class="content" src="/assets/img/news/n2.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="container neumorphism pressed">
			<span class="msg date">2021/4/1</span>
			<div class="text">
				<h1 class="msg title">
					<span>SDGs宣言を行いました。</span>
				</h1>
				<p class="msg sentence">
					<span>当社は、「システム開発を通じて</span>
					<span>社員と、お客様と、その家族の幸せを</span>
					<span>構築する」という経営理念のもと、</span>
					<span>事業活動を通じて「持続可能な</span>
					<span>開発目標(SDGs)」の達成に</span>
					<span>貢献し、地域課題の解決および、</span>
					<span>持続可能な社会の実現に</span>
					<span>努めてまいります。</span>
					<a href="/assets/pdf/SSP_SDGs.pdf" target="_brank"
						>PDFはこちら</a
					>
				</p>
			</div>
			<div class="img"></div>
		</div>
		<div class="container neumorphism pressed">
			<span class="msg date">2021/4/1</span>
			<div class="text">
				<h1 class="msg title">
					<span>代表交代のお知らせ</span>
				</h1>
				<p class="msg sentence">
					<span>この度、</span>
					<span>岩本透は代表取締役社長を辞任し</span>
					<span>代表取締役会長に就任いたしました。</span>
					<br />
					<span>また、岩本透の後任として</span>
					<span>岩本佳が代表取締役社長に</span>
					<span>就任いたしました。</span>
					<br />
					<span>これからも全社員一致団結して</span>
					<span>経済発展のため</span>
					<span>努力いたす所存ですので、</span>
					<br />
					<span>なお一層のご支援を賜りますよう</span>
					<span>お願い申し上げます。</span>
				</p>
			</div>
			<div class="img"></div>
		</div>
		<div class="container neumorphism pressed">
			<span class="msg date">2021/4/1</span>
			<div class="text">
				<h1 class="msg title">
					<span>ホームページを</span>
					<span>リニューアルしました。</span>
				</h1>
				<p class="msg sentence"></p>
			</div>
			<div class="img"></div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'; // ①

export default {
	name: 'foot',
	components: {
		Footer,
	},
};
</script>

<style lang="scss" scoped>
.container {
	.msg {
		span {
			display: inline-block;
		}
	}
	.date {
		grid-area: date;
		font-size: 2rem;
	}
	.text {
		grid-area: text;
	}
	.title {
		font-size: 3rem;
		font-family: var(--title-font);
	}
	.sentence {
		font-size: 1.6rem;
		line-height: 3.5rem;
	}
	.list{
		list-style-type: decimal;
	}
	.img{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 300px));
	justify-content: left;
  gap: 25px;
		.content{
		display: block;
		width: 300px;
		height: 200px;
		}
	}
	justify-self: center;
	background-color: transparent;
	display: grid;
	grid-template: repeat(2, auto) / repeat(2, auto);
	grid-template-areas: 'date date' 'text img' 'text img';
	width: 960px;
	margin: 2.5rem;
	padding: 2.5rem;
}
@media (max-width: 1359px) {
	.container {
		.sentence {
			width: 100%;
		}
		width: calc(960px - 10rem);
	}
}
@media (max-width: 959px) {
	.container {
		width: calc(100% - 10rem);
		border-radius: 0rem;
	}
}
@media (max-width: 750px) {
	.container {
		margin: 2rem;
		padding: 2rem;
		width: calc(100% - 8rem);
	}
}
@media (max-width: 519px) {
	.container {
		margin: 1rem;
		padding: 1rem;
		width: calc(100% - 4rem);
	}
}
</style>

<template>
  <div class="Inquire">
    <h1>This is an Inquire page</h1>
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'  // ①
 
export default {
    name:'foot',
  components: {
    Footer
  }
}
</script>

<template>
  <div id="app">
    <div id="titlebar" class="neumorphism bottom">
      <router-link to="/" id="logo">
        <div id="brand-logo" class="img"></div>
      </router-link>
      <nav id="menubar">
        <router-link exact to="/" class="menubar-item">ホーム</router-link>
        <router-link exact to="/About" class="menubar-item"
          >会社情報</router-link
        >
        <router-link exact to="/Products" class="menubar-item"
          >商品情報</router-link
        >
        <router-link exact to="/Initiatives" class="menubar-item"
          >取り組み</router-link
        >
        <router-link exact to="/Recruitment" class="menubar-item"
          >採用情報</router-link
        >
              <router-link exact to="/News" class="menubar-item">ニュース</router-link>
        <a
          href="https://www.s-system.co.jp/contact/mailform/"
          target="_blank"
          blanc
          class="menubar-item"
          >お問い合わせ</a
        >
      </nav>
      <label
        id="showmenu"
        for="menu-operate"
        v-on:mousedown.prevent="start"
        v-on:touchstart.prevent="start"
      >
        <span id="showmenu-icon" class="material-icons neumorphism">menu</span>
      </label>
    </div>
    <label id="menu" for="menu-operate">
      <router-link exact to="/" class="menubar-item">ホーム</router-link>
      <router-link exact to="/About" class="menubar-item">会社情報</router-link>
      <router-link exact to="/Products" class="menubar-item"
        >商品情報</router-link
      >
      <router-link exact to="/Initiatives" class="menubar-item"
        >取り組み</router-link
      >
      <router-link exact to="/Recruitment" class="menubar-item"
        >採用情報</router-link
      >
      <router-link exact to="/News" class="menubar-item">ニュース</router-link>
      <a
        href="https://www.s-system.co.jp/contact/mailform/"
        target="_blank"
        blanc
        class="menubar-item"
        >お問い合わせ</a
      >
    </label>
    <router-view id="container" />
  </div>
</template>
<script>
export default {
  name: "MouseTouchStart",
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    $route() {
      const menu = document.getElementById("menu");
      let showmenu = document.getElementById("showmenu-icon");
      menu.classList.remove("on");
      showmenu.classList.remove("pressed");
    },
  },
  methods: {
    start() {
      let menu = document.getElementById("menu");
      let target = document.getElementById("showmenu-icon");
      let container = document.getElementById("container");
      if (target.classList.contains("pressed")) {
        target.classList.remove("pressed");
        menu.classList.remove("on");
        container.style.setProperty("display", null);
      } else {
        container.style.setProperty("display", "none");
        target.classList.add("pressed");
        menu.classList.add("on");
      }

      this.isActive = true;
    },
  },
};
</script>
<style lang="scss">
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Tangerine&display=swap");
:root {
  font-size: 10px;
  --title-font: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro",
    "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  --normal-font: -apple-system, blinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-family: var(--normal-font);
  --green: rgba(51, 204, 153, 1);
  @media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
    --bgc-pri-pri:white;
    --acc-pri: #00d4b8;
    --acc-sec: #3399ff;
    --fgc-pri: #1b1c1d;
    --bgc-pri: #e0e5ec;
    --bgc-sec: #ffffff;
    --bgc-thi: #bec3c9;
    --neumorphism-concave: linear-gradient(145deg, #caced4, #f0f5fd);
    --neumorphism-concex: linear-gradient(145deg, #f0f5fd, #caced4);
    &[data-inverted-color] {
      --bgc-pri-pri:black;
      --acc-pri: #00d4b8;
      --acc-sec: #3399ff;
      --fgc-pri: #c1c5c6;
      --bgc-pri: #1b1c1d;
      --bgc-sec: #31353d;
      --bgc-thi: #000000;
      --neumorphism-concave: linear-gradient(145deg, #18191a, #1d1e1f);
      --neumorphism-convex: linear-gradient(145deg, #1d1e1f, #18191a);
    }
  }
  @media (prefers-color-scheme: dark) {
    --bgc-pri-pri:black;
    --acc-pri: #00d4b8;
    --acc-sec: #3399ff;
    --fgc-pri: #c1c5c6;
    --bgc-pri: #1b1c1d;
    --bgc-sec: #31353d;
    --bgc-thi: #000000;
    --neumorphism-concave: linear-gradient(145deg, #18191a, #1d1e1f);
    --neumorphism-convex: linear-gradient(145deg, #1d1e1f, #18191a);
    &[data-inverted-color] {
      --bgc-pri-pri:white;
      --acc-pri: #00d4b8;
      --acc-sec: #3399ff;
      --fgc-pri: #1b1c1d;
      --bgc-pri: #e0e5ec;
      --bgc-sec: #ffffff;
      --bgc-thi: #bec3c9;
      --neumorphism-concave: linear-gradient(145deg, #caced4, #f0f5fd);
      --neumorphism-concex: linear-gradient(145deg, #f0f5fd, #caced4);
    }
  }
}
.floot-std {
  font-family: flood-std, sans-serif;
  font-style: normal;
  font-weight: 400;
}
.neumorphism {
  &.top {
    box-shadow: 0 -10px 7px -7px var(--bgc-sec),
      inset 0 10px 7px -7px var(--bgc-thi);
  }
  &.right {
    box-shadow: 10px 0 7px -7px var(--bgc-thi),
      inset -10px 0 7px -7px var(--bgc-sec);
  }
  &.left {
    box-shadow: -10px 0 7px -7px var(--bgc-sec),
      inset 10px 0 7px -7px var(--bgc-thi);
  }
  &.bottom {
    box-shadow: 0 10px 7px -7px var(--bgc-thi),
      inset 0 -10px 7px -7px var(--bgc-sec);
  }
  &.concave {
    background: var(--neumorphism-concave);
  }
  &.convex {
    background: var(--neumorphism-concex);
  }
  &.pressed {
    //background: var(--bgc-pri);
    box-shadow: -0.3rem -0.3rem 0.7rem var(--bgc-sec),
      0.3rem 0.3rem 0.5rem var(--bgc-thi),
      inset -0.3rem -0.3rem 0.7rem var(--bgc-sec),
      inset 0.3rem 0.3rem 0.5rem var(--bgc-thi);
  }
  box-shadow: -0.5rem -0.5rem 0.7rem var(--bgc-sec),
    0.5rem 0.5rem 0.5rem var(--bgc-thi);
  background-color: var(--bgc-pri);

  /*     &.primary {
        &.regular {
            box-shadow: 15px 15px 30px #00b49c, -15px -15px 30px #00f4d4;
        }
        &.card {
            background: linear-gradient(145deg, #2e8ae6, #37a4ff);
            box-shadow: 15px 15px 60px #2b82d9, -15px -15px 60px #3bb0ff;
        }
        background: linear-gradient(145deg, #00bfa6, #00e3c5);
        border-radius: 0px;
        background-color: var(--acc-pri);
    } */
}
.img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
body {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0 0 0 0;
  margin: auto;
  color: var(--fgc-pri);
  background-color: var(--bgc-pri);
}
#app {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  grid-template-rows: 65px 1fr;
  grid-template-columns: 100%;
}
#menu {
  &.on {
    display: grid;
  }
  display: none;
  grid-auto-flow: row;
  justify-self: end;
  align-self: center;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  .menubar-item {
    &.router-link-active {
      color: var(--acc-pri);
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--bgc-sec);
    }
    position: relative;
    font-size: 3rem;
    text-decoration: none;
    color: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bgc-pri);
  }
}
#titlebar {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
}
#brand-logo {
  @media (prefers-color-scheme: dark) {
    background-image: url("/assets/img/ssp_text_only_white.svg");
  }
  background-image: url("/assets/img/ssp_text_only_black.svg");
  width: 80px;
  height: 100%;
}
#logo {
  &:hover {
    color: var(--green);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  width: 80px;
  text-decoration: none;
  color: inherit;
}
#menubar {
  .menubar-item {
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 1px;
      left: 0;
      bottom: 1rem;
      right: 0;
      margin: auto;
      transition: 0.2s ease-in-out;
      background-color: var(--acc-pri);
    }
    &:hover {
      &::after {
        width: 80%;
      }
    }
    &.router-link-active {
      &::after {
        width: 80%;
      }
      color: var(--acc-pri);
    }
    position: relative;
    font-size: 1.5rem;
    padding: 2.25rem;
    text-decoration: none;
    color: inherit;
  }
  justify-self: end;
  align-self: center;
}
#showmenu {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    user-select: none;
  }
/*   &.pressed {
    //color: var(--acc-pri);
  } */
  display: none;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 100%;
  position: relative;
  font-size: 2.4rem;
  cursor: pointer;
  justify-self: end;
  align-self: center;
}
#container {
  display: grid;
  overflow: auto;
}
@media (max-width: 1359px) {
}
@media (max-width: 959px) {
  #menubar {
    display: none;
  }
  #showmenu {
    display: flex;
  }
}
@media (max-width: 519px) {
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Products from '../views/Products.vue'
import Initiatives from '../views/Initiatives.vue'
import Recruitment from '../views/Recruitment.vue'
import News from '../views/News.vue'
import Inquire from '../views/Inquire.vue'
import Policy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/Products',
    name: 'Products',
    component: Products
  },
  {
    path: '/Initiatives',
    name: 'Initiatives',
    component: Initiatives
  },
  {
    path: '/Recruitment',
    name: 'Recruitment',
    component: Recruitment
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/Inquire',
    name: 'Inquire',
    component: Inquire
  },
  {
    path: '/Policy',
    name: 'Policy',
    component: Policy
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router

<template>
  <div>
    <div id="sdgs" class="initiatives container neumorphism">
      <div id="sdgs-logo" class="img"></div>
      <div class="initiatives-message">
        <h1 class="message-title">
          <span>SDGSへの</span>
          <span>取り組み</span>
        </h1>
        <p class="message-sentence">
          <span class="f">SSPは持続可能な開発目標の</span>
          <span>達成に貢献するため、</span>
          <br />
          <span>さまざまな活動に</span>
          <span>取り組んでいます。</span>
          <br />
          <span class="f">ICTで解決できる課題を</span>
          <span>お客様と一緒に考え、</span>
          <br />
          <span>実現可能な社会を目指し、</span>
          <span>社員とお客様と</span>
          <span>その家族の幸せを構築します。</span>
        </p>
      </div>
    </div>
    <div id="riss" class="initiatives container neumorphism">
      <div id="riss-logo" class="img"></div>
      <div class="initiatives-message">
        <h1 class="message-title">
          <span>RISSへの</span>
          <span>取り組み</span>
        </h1>
        <p class="message-sentence">
          <span class="f">私達は基幹系システムの開発を</span>
          <span>40年間続け、</span>
          <span>その経験を</span>
          <span>システムに反映し、</span>
          <br />
          <span>より良いシステムの開発に</span>
          <span>取り組んでいます。</span>
          <br />
          <span>また、システム開発を</span>
          <span>安全で確実に遂行するため、</span>
          <br />
          <span>すべてのプロジェクトにおいて、</span>
          <span>情報処理安全確保支援士を</span>
          <span>配置しております。</span>
        </p>
      </div>
    </div>
    <div id="pmark" class="initiatives container neumorphism">
      <div id="pmark-logo" class="img"></div>
      <div class="initiatives-message">
        <h1 class="message-title">
          <span>プライバシーマーク</span>
          <span>制度</span>
          <span>への</span>
          <span>取り組み</span>
        </h1>
        <p class="message-sentence">
          <span>
          SSPは2008年に</span>
          <span>プライバシーマークの認定を</span>
          <span>取得しました。</span>
          <br>
          <span>データ管理・運用方法、</span>
          <span>ITリテラシーについて社員に</span>
          <span>徹底した教育を行っています。</span>
          <br>
          <span>お客様に安心して</span>
          <span>任せていただけるように、</span>
          <span>SSPは今後も継続して</span>
          <span>努めてまいります。</span>
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue"; // ①

export default {
  name: "foot",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.container {
  justify-self: center;
  background-color: transparent;
  width: 1200px;
  margin: 2.5rem;
  padding: 2.5rem;
}
.initiatives {
  .initiatives-message {
    .message-title {
      span {
        display: inline-block;
        text-align: center;
      }
      font-size: 3rem;
      text-align: center;
    }
    .message-sentence {
      font-size: 2.25rem;
      font-weight: 700;
      br + span {
        margin-top: 1rem;
      }
      span {
        display: inline-block;
        text-align: center;
      }
      text-align: center;
    }
  }
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;
  align-items: center;
  font-family: var(--title-font);
}
#sdgs {
  #sdgs-logo {
    width: 100%;
    height: 200px;
    background-image: url("/assets/img/initiatives/sdgs_w_t.png");
  }
}
#riss {
  #riss-logo {
    width: 300px;
    height: 300px;
    justify-self: center;
    background-image: url("/assets/img/initiatives/riss.png");
  }
}
#pmark {
  #pmark-logo {
    width: 300px;
    height: 300px;
    justify-self: center;
    background-image: url("/assets/img/initiatives/pmark.png");
  }
  position: relative;
  justify-self: center;
}
@media (max-width: 1359px) {
  .container {
    width: calc(960px - 10rem);
  }
}
@media (max-width: 959px) {
  .container {
    width: calc(100% - 10rem);
    border-radius: 0rem;
  }
  #sdgs {
    #sdgs-logo {
      height: 300px;
      background-image: url("/assets/img/initiatives/sdgs_s_t.png");
    }
  }
}
@media (max-width: 750px) {
  .container {
    margin: 2rem;
    padding: 2rem;
    width: calc(100% - 8rem);
  }
  .initiatives {
    .initiatives-message {
      .message-sentence {
        font-size: 1.85rem;
        font-weight: 700;
      }
    }
  }
}
@media (max-width: 519px) {
  .container {
    margin: 1rem;
    padding: 1rem;
    width: calc(100% - 4rem);
  }
  .initiatives {
    .initiatives-message {
      .message-sentence {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
  }
  #sdgs {
    #sdgs-logo {
      height: 250px;
    }
  }
  #riss {
    #riss-logo {
      width: 250px;
      height: 250px;
    }
  }
  #pmark {
    #pmark-logo {
      width: 250px;
      height: 250px;
    }
  }
}
</style>

<template>
	<div>
		<div id="chairman" class="greeding container neumorphism">
			<div class="greeding-message">
				<h1 class="message-title">代表取締役会長</h1>
				<p class="message-sentence">
					<span>新卒でこの業界に入社し、</span>
					<span>間もなく当社を設立致しました。</span>
					<span>約40年前の事です。</span>
					<span>その時代は一般中小企業が</span>
					<span>オフィスコンピュータの導入を</span>
					<span>始めた頃で、まだ殆どの中小企業が</span>
					<span>利用していない時期でした。</span>
					<span>効果は事務処理の自動化、</span>
					<span>いわゆる転記、計算、集計の自動化に</span>
					<span>よって経費の削減と計算ミスの解消。</span>
					<span>また、日々変化する在庫の</span>
					<span>数量管理でした。</span>
					<br />
					<span>私の師匠はお客様でした。</span>
					<span>実現したい事や夢を毎日</span>
					<span>お聞きしながら、</span>
					<span>自分もその会社の一員として、</span>
					<span>どうすれば実現できるのか？と</span>
					<span>毎日昼夜を問わず</span>
					<span>自問自答しておりました。</span>
					<span>まず今を知らなければ何も進まない事</span>
					<span>に気が付き、現状を理解する為に、</span>
					<span>業務の流れを何度も図形化しました。</span>
					<span>業務の流れには、情報の流れと</span>
					<span>物の流れの二つの種類があります。</span>
					<span>必然的に二種類の</span>
					<span>業務フローチャートを描き、</span>
					<span>二つの情報の不一致を</span>
					<span>何とか解決する方法を模索しました。</span>
					<span>品物が動いても入力されてなければ</span>
					<span>情報は動いていない。</span>
					<span>物が動いた都度入力するには手間が</span>
					<span>掛かり合理化にならないばかりか、</span>
					<span>使い物にならないシステムが</span>
					<span>作られてしまう。</span>
					<br />
					<span>なるべく早いタイミングで</span>
					<span>より簡単に入力できる方法を、</span>
					<span>対象のお客様毎に考えていきました。</span>
					<span>入力方法の工夫として、</span>
					<span>商品コード入力が大半だった時代で、</span>
					<span>商品コードを使わない画面で</span>
					<span>検索入力する方法、その機能強化で</span>
					<span>販売単価マスターを新設し、</span>
					<span>販売実績のある商品に絞り検索の</span>
					<span>件数を絞り込む方法を工夫して、</span>
					<span>システムに取り込んできました。</span>
					<span>結果、順調に顧客数も増加し、</span>
					<span>私個人にも多大な業務知識や改善例が</span>
					<span>身に付き、現在に至っては</span>
					<span>業務改善コンサルタント的な技能を</span>
					<span>得る事ができました。</span>
					<span>また、プログラムを設計、制作する</span>
					<span>システムエンジニアや</span>
					<span>プログラマーも、</span>
					<span>これが出来ないか？</span>
					<span>これなら出来るか？</span>
					<span>と経験による技能が向上し、</span>
					<span>やはりコンサルテーションの出来る</span>
					<span>技術者に育っていきました。</span>
					<span>これは結果的に私を今まで</span>
					<span>ビジネスとして支えてくれた、</span>
					<span>お金では買う事の出来ない</span>
					<span>ノウハウとなりました。</span>
					<span>その頃から、</span>
					<span>無から有を創作するソフトビジネス、</span>
					<span>作れる力を捨てないソフト企業として</span>
					<span>成長させて行く事を誓いました。</span>
					<br />
					<span>無から有を作るには、多大な努力や</span>
					<span>疲労、我慢も必要となります。</span>
					<span>しかしながらこれからも新たな事に</span>
					<span>チャレンジし、作り上げる</span>
					<span>喜びを感じながら、新しいチャレンジ</span>
					<span>から新たな商品を生み出す事を</span>
					<span>続けようと思います。</span>
					<span>現代社会は遥かに技術進歩して、</span>
					<span>インターネットの恩恵で多くの不可能が</span>
					<span>可能になりました。</span>
					<span>まだまだ進歩されると思います。</span>
					<br />
					<span>大きなビジネスチャンスや素材が</span>
					<span>DX等の普及で拡大されています。</span>
					<span>我が社は、</span>
					<span>いつまでもチャレンジを続ける企業で</span>
					<span>ありたいと思っています。</span>
				</p>
			</div>
			<center class="greeding-people">
				<img
					class="people-img"
					src="/assets/img/company/n1.png"
					alt=""
				/>
				<div class="people-description">
					<center class="pos">代表取締役会長</center>
					<center class="name">岩本 透</center>
				</div>
			</center>
		</div>
		<div id="president" class="greeding container neumorphism">
			<div class="greeding-message">
				<h1 class="message-title">代表取締役社長</h1>
				<p class="message-sentence">
					<span class="f">1982年(昭和57年)に</span>
					<span>愛媛県松山市で</span>
					<span>四国システム株式会社として</span>
					<span>システム会社を設立し、</span>
					<span>中小企業様を中心に</span>
					<span>現在も基幹系ソフトウェアの</span>
					<span>受託オーダー開発を行っています。</span>
					<br />
					<span class="f">2013年(平成25年)には</span>
					<span>株式会社SSPと社名を変更し、</span>
					<span>新たな取り組みとして</span>
					<span>業種に特化したパッケージを</span>
					<span>全国の対象業種企業へ</span>
					<span>販売を開始しました。</span>
					<span>当社が開発しているソフトウェアは</span>
					<span>創業から、ほぼ100％</span>
					<span>自社で開発しており</span>
					<span>導入後も運用サポートを</span>
					<span>行っています。</span>
					<span>自社開発製品のため、</span>
					<span>業務運用の変更や機能追加など</span>
					<span>プログラム修正も可能です。</span>
					<br />
					<span class="f">SSPは2021年(令和3年)で</span>
					<span>40期を迎えます。</span>
					<span>40期を迎えることができるのは、</span>
					<span>今まで支えてくれた社員と</span>
					<span>そのご家族の皆様、</span>
					<span>そして長きに渡り</span>
					<span>お付き合いしてくださる</span>
					<span>お取引様のおかげであり、</span>
					<span>感謝を申し上げます。</span>
					<br />
					<span class="f">システムとは企業が業務を</span>
					<span>潤滑に回すためのツールであり</span>
					<span>経営判断をするための頭脳であると</span>
					<span>思っています。</span>
					<br />
					<span class="f">今後、DXやAIなど</span>
					<span>企業を取り巻く環境が</span>
					<span>大きく変化していきます。</span>
					<span>しかし、先端技術は正しい情報が</span>
					<span>あって初めて企業で活躍されます。</span>
					<br />
					<span class="f">私たちはシステム会社として</span>
					<span>先端技術の情報提供や</span>
					<span>推進活動を行って参りますが、</span>
					<span>それ以上に</span>
					<span>現在かかえている状況・問題を</span>
					<span>お客様と一緒に解決できる会社を</span>
					<span>目指します。</span>
					<br />
					<span class="f">『1時間を1分に』</span>
					<span>お客様が普段業務を</span>
					<span>行っている作業時間で</span>
					<span>1時間かかるものがあった場合、</span>
					<span>それをシステムで</span>
					<span>1分にすることを目標としています。</span>
					<br />
					<span class="f">普段の業務で時間がかかっていると</span>
					<span>感じにくいこともあるかと思います。</span>
					<span>SSPはお客様と一緒に</span>
					<span>業務改善を考えていきたいと</span>
					<span>思っています。</span>
					<br />
					<span class="f">引き続きご支援、</span>
					<span>ご指導を賜りますよう</span>
					<span>宜しくお願い申し上げます。</span>
				</p>
			</div>
			<center class="greeding-people">
				<img
					class="people-img"
					src="/assets/img/company/n2.png"
					alt=""
				/>
				<div class="people-description">
					<center class="pos">代表取締役社長</center>
					<center class="name">岩本 佳</center>
				</div>
			</center>
		</div>
		<div id="profile" class="container neumorphism">
			<div class="bg">
				<div class="logo img"></div>
			</div>
			<div id="profile-content">
				<div id="overview">
					<span class="profile-title">概要</span>
					<ul class="profile-value">
						<li>
							<span>会社名</span>
							<p>
								<span>株式会社 SSP</span>
							</p>
						</li>
						<li>
							<span>郵便番号</span>
							<p><span>791-8013</span></p>
						</li>
						<li>
							<span>所在地</span>
							<p>
								<span>愛媛県松山市</span
								><span>山越6丁目17番11号</span>
							</p>
						</li>
						<li>
							<span>TEL</span>
							<p>
								<span>089-924-7080</span>
							</p>
						</li>
						<li>
							<span>FAX</span>
							<p>
								<span>089-922-1387</span>
							</p>
						</li>
						<li>
							<span>資本金</span>
							<p>
								<span>1000万円</span>
							</p>
						</li>
						<li>
							<span>営業時間</span>
							<p>
								<span>9 a.m. - 5:30 p.m.</span>
							</p>
						</li>
						<li>
							<span>社員数</span>
							<p>
								<span>20名</span>
							</p>
						</li>
					</ul>
				</div>
				<div id="history">
					<span class="profile-title">沿革</span>
					<ul class="profile-value">
						<li>
							<span>1982/4</span>
							<p>
								<span class="f">四国システム(株)設立</span>
								<br />
								<span class="f">販売管理システムの</span
								><span>販売・開発を実施</span>
							</p>
						</li>
						<li>
							<span>2000</span>
							<p>
								<span class="f">オフィスコンピュータの</span
								><span>販売・開発を廃止</span>
								<br />
								<span class="f">オープンシステムへ</span
								><span>全面転換開始</span>
							</p>
						</li>
						<li>
							<span>2008</span>
							<p>
								<span class="f">プライバシーマーク取得</span
								><span>(第10822877号)</span>
							</p>
						</li>
						<li>
							<span>2010</span>
							<p>
								<span class="f">業種別自社システムを</span
								><span>開発・全国へ販売開始</span>
							</p>
						</li>
						<li>
							<span>2013/3</span>
							<p>
								<span class="f">株式会社 SSPに社名変更</span>
							</p>
						</li>
						<li>
							<span>2017</span>
							<p>
								<span class="f">IT導入補助金認定</span
								><span>(IT導入支援事業者)</span>
							</p>
						</li>
						<li>
							<span>2021/4</span>
							<p>
								<span class="f"
									>岩本透 代表取締役会長に就任</span
								>
								<br />
								<span class="f"
									>岩本佳 代表取締役社長に就任</span
								>
								<br />
								<span class="f"
									>持続可能な開発目標(SDGs)への取り組みを開始</span
								>
							</p>
						</li>
					</ul>
				</div>
				<div id="philosophy">
					<span class="profile-title">理念</span>
					<ul class="profile-value">
						<li>
							<p>
								<span class="f">システム開発を通じて</span
								><span>社員と、お客様と、</span
								><span>その家族の幸せを構築する。</span><br />
								<span class="f">私たちはそれを</span
								><span>実現することができる企業</span
								><span>として努力し貢献する。</span>
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '@/components/Footer.vue'; // ①

export default {
	name: 'foot',
	components: {
		Footer,
	},
};
</script>

<style lang="scss" scoped>
@import url('https://use.typekit.net/bux4heh.css');
.container {
	justify-self: center;
	background-color: transparent;
	width: 1200px;
	margin: 2.5rem;
	padding: 2.5rem;
}
.greeding {
	.greeding-people {
		.people-img {
			height: 300px;
		}
		.people-description {
			.pos {
				font-size: 1.6rem;
			}
			.name {
				font-size: 2.5rem;
			}
			font-weight: 700;
		}
	}
	.greeding-message {
		.message-title {
			&::after {
				content: '・ご挨拶';
			}
			font-size: 3rem;
		}
		.message-sentence {
			font-size: 1.6rem;
			font-weight: 700;
			br + span {
				margin-top: 2.5rem;
			}
			span {
				display: inline-block;
				text-align: center;
			}
		}
	}
	display: grid;
	grid-auto-flow: column;
	gap: 1.5rem;
	align-items: center;
	font-family: var(--title-font);
}
#profile {
	.bg {
		.logo {
			position: sticky;
			top: 0;
			right: 0;
			left: 0;
			margin: auto;
			width: 500px;
			height: 500px;
			background-image: url('/assets/img/ssp_t_black.svg');
			@media (prefers-color-scheme: dark) {
				background-image: url('/assets/img/ssp_t_white.svg');
			}
		}
		position: absolute;
		right: 3rem;
		height: 100%;
		opacity: 0.1;
	}
	.profile-title {
		font-size: 3rem;
	}
	.profile-value {
		li {
			display: grid;
			grid-template-columns: 100px 1fr;
			align-items: center;
		}
		span {
			display: inline-block;
			text-align: left;
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 1px;
			height: 100%;
			background-color: var(--fgc-pri);
		}
		position: relative;
		margin-inline-start: 3rem;
		padding-inline-start: 5rem;
		font-size: 1.6rem;
		font-weight: 700;
	}
	#philosophy {
		.profile-value {
			li {
				display: unset;
			}
		}
	}
	ul {
		list-style: none;
	}
	font-family: var(--title-font);
	position: relative;
	justify-self: center;
}

@media (max-width: 1359px) {
	.container {
		width: calc(960px - 10rem);
	}
}
@media (max-width: 959px) {
	.container {
		width: calc(100% - 10rem);
		border-radius: 0rem;
	}
	.greeding {
		grid-auto-flow: row;
		text-align: center;
	}
}
@media (max-width: 750px) {
	.container {
		margin: 2rem;
		padding: 2rem;
		width: calc(100% - 8rem);
	}
	#profile {
		.bg {
			.logo {
				width: 200px;
				height: 200px;
			}
		}
		.profile-value {
			&::before {
				display: none;
			}
			li {
				span {
					text-align: left;
				}
				p {
					padding-inline-start: 1.5rem;
					margin-block-start: 0.5rem;
				}
				grid-auto-flow: row;
				grid-template-rows: auto 1fr;
				grid-template-columns: unset;
				border-bottom: 1px solid var(--bgc-sec);
			}
			padding-inline-start: 0rem;
			margin-left: 1rem;
		}
		#philosophy {
			li {
				p {
					text-align: center;
					padding-inline-start: 0rem;
				}
			}
		}
	}
}
@media (max-width: 519px) {
	.container {
		margin: 1rem;
		padding: 1rem;
		width: calc(100% - 4rem);
	}
	.greeding {
		.greeding-message {
			.message-title {
				&::after {
					content: '\Aご挨拶';
				}
				white-space: pre;
			}
		}
	}
}
</style>
